function LeftSidebar() {

    return (
        <div class="col-lg-3">
                            <div class="card border-0 p-4 tab-button">

                                <div class="sidebar-profile d-flex align-items-center">
                                    <img src="images/profile-img.png" class="img-fluid rounded-circle" />

                                    <div class="ml-3 ">
                                        <h5>Tome Joe</h5>

                                        <label class="default-label mt-1">Default Package</label>

                                    </div>

                                </div>

                                <ul class="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                                    <li class="nav-item w-100">
                                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"> <img src="images/ico-dashboard.png" width="18" /> Dashboard </a>
                                    </li>
                                    <li class="nav-item w-100">
                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"> <img src="images/ico-gallery.png" width="16" /> Gallery</a>
                                    </li>
                                    <li class="nav-item  w-100">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"> <img src="images/ico-happy-story.png" width="20" /> Happy Story</a>
                                    </li>
                                    <li class="nav-item  w-100">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"> <img src="images/ico-package.png" width="20" /> Package</a>
                                    </li>
                                    <li class="nav-item  w-100">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"> <img src="images/ico-wallet.png" width="20" /> My Wallet</a>
                                    </li>
                                    <li class="nav-item  w-100">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"> <img src="images/ico-message.png" width="20" /> Messages</a>
                                    </li>
                                </ul>
                            </div>


                        </div>
    );
}

export default LeftSidebar;