import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DefaultLayout from "./layouts/DefaultLayout";
import AuthLayout from "./layouts/AuthLayout";

import Home from "./pages/Home";
import Contactus from "./pages/Contactus";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import CancellationReturnPolicy from "./pages/CancellationReturnPolicy";
import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DefaultLayout />} >
          <Route index element={<Home />} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cancellation-return-policy" element={<CancellationReturnPolicy />} />
        </Route>

        <Route element={<AuthLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
      </Routes>
    </Router>
  );
}

export default App;
