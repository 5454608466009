import { Outlet } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import LeftSidebar from "./components/LeftSidebar";

function AuthLayout() {
    return (
        <>
            <div class="wrapper">
                <Header />

                <section class="headerafter dashboard-wrapper mb-5">
                    <div class="container">
                        <div class="row">

                            <LeftSidebar />
                            <Outlet />

                        </div>
                    </div>
                </section>
                <Footer />

                <a href="#" id="back-to-top" title="Back to top">&uarr;</a>
            </div>
        </>
    )
}

export default AuthLayout;