import { useEffect, useState } from "react";

function Home() {

  const [regTab, setRegTab] = useState(1);

    useEffect(() => {
      setTimeout(() => {
        window.$(".profile").slick({
            slidesToShow: 4,
            dots: false,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: true,
            centerPadding: "60px",

            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            }, {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }, ],
        });
      }, 2000)
    }, [])

    return (
        <>
            <div class="banner-wrap">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-7 d-flex align-items-center">
                        <h1>
                            India's most trusted <br /> official matrimony <br /> service exclusively <br /> for Muslims
                        </h1>
                    </div>
                    <div class="col-xl-5">
                        <div class="register-form">
                          {regTab == 1 ? (
                            <>
                            <div class="register-title">
                                <h4>Register Now<span> - its free</span></h4>
                            </div>

                            <div class="form-content p-4">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Create Profile for *</label
                          >
                          <select
                            class="form-control"
                            id="profilefor"
                            name="profilefor"
                          >
                            <option value="">Select</option>
                            <option value="1">My Self</option>
                            <option value="2">Daughter</option>
                            <option value="3">Son</option>
                            <option value="5">Sister</option>
                            <option value="6">Brother</option>
                            <option value="4">Friend</option>
                            <option value="7">Relative</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1"
                            >Bride / Groom Name *</label
                          >

                          <input
                            type="text"
                            class="form-control"
                            placeholder="Bride / Groom"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Phone Number *</label>
                                                <div class="row">
                                                    <div class="col-sm-5">
                                                        <select class="form-control mb-sm-20" id="txtcntycode" name="Countrycode">
                                <option value="">ISD</option>
                                <optgroup label="Frequent Countries">
                                  <option value="4">Afghanistan (+93)</option>
                                  <option value="5">Algeria (+213)</option>
                                  <option value="94">Australia (+61)</option>
                                  <option value="21">Bangladesh (+880)</option>
                                  <option value="15">Bahrain (+973)</option>
                                  <option value="34">Canada (+1)</option>
                                  <option value="58">Egypt (+20)</option>
                                  <option value="72">France (+33)</option>
                                  <option value="12">India (+91)</option>
                                  <option value="93">Indonesia (+62)</option>
                                  <option value="95">Iran (+98)</option>
                                  <option value="103">Jordan (+962)</option>
                                  <option value="110">Kuwait (+965)</option>
                                  <option value="140">Malaysia (+60)</option>
                                  <option value="113">Morocco (+212)</option>
                                  <option value="137">Oman (+968)</option>
                                  <option value="159">Pakistan (+92)</option>
                                  <option value="165">Qatar (+974)</option>
                                  <option value="171">
                                    Saudi Arabia (+966)
                                  </option>
                                  <option value="181">Singapore (+65)</option>
                                  <option value="175">
                                    South Africa (+27)
                                  </option>
                                  <option value="35">Sri Lanka (+94)</option>
                                  <option value="199">Tunisia (+216)</option>
                                  <option value="3">UAE (+971)</option>
                                  <option value="207">
                                    United Kingdom (+44)
                                  </option>
                                  <option value="209">
                                    United States of America (+1)
                                  </option>
                                  <option value="" disabled=""></option>
                                </optgroup>
                                <option value="4">Afghanistan (93)</option>
                                <option value="218">Aland Islands(358)</option>
                                <option value="7">Albania (355)</option>
                                <option value="5">Algeria (213)</option>
                                <option value="223">
                                  American Samoa (684)
                                </option>
                                <option value="9">Andorra (376)</option>
                                <option value="10">Angola (244)</option>
                                <option value="14">Anguilla (1)</option>
                                <option value="239">Antarctica (672)</option>
                                <option value="2">
                                  Antigua and Barbuda (1)
                                </option>
                                <option value="11">Argentina (54)</option>
                                <option value="8">Armenia (374)</option>
                                <option value="1">Aruba (297)</option>
                                <option value="94">Australia (61)</option>
                                <option value="13">Austria (43)</option>
                                <option value="6">Azerbaijan (994)</option>
                                <option value="20">Bahamas (1)</option>
                                <option value="15">Bahrain (973)</option>
                                <option value="21">Bangladesh (880)</option>
                                <option value="16">Barbados (1)</option>
                                <option value="27">Belarus (375)</option>
                                <option value="19">Belgium (32)</option>
                                <option value="22">Belize (501)</option>
                                <option value="26">Benin (229)</option>
                                <option value="18">Bermuda (1)</option>
                                <option value="30">Bhutan (975)</option>
                                <option value="24">Bolivia (591)</option>
                                <option value="23">
                                  Bosnia and Herzegovina (387)
                                </option>
                                <option value="17">Botswana (267)</option>
                                <option value="29">Brazil (55)</option>
                                <option value="32">Brunei(673)</option>
                                <option value="31">Bulgaria (359)</option>
                                <option value="210">Burkina Faso (226)</option>
                                <option value="33">Burundi (257)</option>
                                <option value="35">Cambodia (855)</option>
                                <option value="44">Cameroon (237)</option>
                                <option value="34">Canada (1)</option>
                                <option value="50">Cape Verde (238)</option>
                                <option value="42">Cayman Islands (1)</option>
                                <option value="48">
                                  Central African Republic (236)
                                </option>
                                <option value="36">Chad (235)</option>
                                <option value="41">Chile (56)</option>
                                <option value="40">China (86)</option>
                                <option value="109">
                                  Christmas Island (672)
                                </option>
                                <option value="43">
                                  Cocos (Keeling) Islands(891)
                                </option>
                                <option value="46">Colombia (57)</option>
                                <option value="45">Comoros (269)</option>
                                <option value="38">Congo (242)</option>
                                <option value="39">
                                  Congo,The Democratic Republic(243)
                                </option>
                                <option value="51">Cook Islands (682)</option>
                                <option value="47">Costa Rica (506)</option>
                                <option value="98">Cote d'Ivoire(225)</option>
                                <option value="90">Croatia (385)</option>
                                <option value="49">Cuba (53)</option>
                                <option value="52">Cyprus (357)</option>
                                <option value="65">Czech Republic (420)</option>
                                <option value="53">Denmark (45)</option>
                                <option value="54">Djibouti (253)</option>
                                <option value="55">Dominica (1)</option>
                                <option value="56">
                                  Dominican Republic (1)
                                </option>
                                <option value="57">Ecuador (593)</option>
                                <option value="58">Egypt (20)</option>
                                <option value="63">El Salvador (503)</option>
                                <option value="60">
                                  Equatorial Guinea (240)
                                </option>
                                <option value="62">Eritrea (291)</option>
                                <option value="61">Estonia (372)</option>
                                <option value="64">Ethiopia (251)</option>
                                <option value="69">
                                  Falkland Islands (Malvinas)(500)
                                </option>
                                <option value="70">Faroe Islands (298)</option>
                                <option value="68">Fiji (679)</option>
                                <option value="67">Finland (358)</option>
                                <option value="72">France (33)</option>
                                <option value="66">French Guiana (594)</option>
                                <option value="71">
                                  French Polynesia (689)
                                </option>
                                <option value="74">Gabon (241)</option>
                                <option value="73">Gambia (220)</option>
                                <option value="75">Georgia (995)</option>
                                <option value="81">Germany (49)</option>
                                <option value="76">Ghana (233)</option>
                                <option value="77">Gibraltar (350)</option>
                                <option value="83">Greece (30)</option>
                                <option value="80">Greenland (299)</option>
                                <option value="78">Grenada (1)</option>
                                <option value="82">Guadeloupe (590)</option>
                                <option value="227">Guam (1)</option>
                                <option value="84">Guatemala (502)</option>
                                <option value="79">Guernsey(44)</option>
                                <option value="85">Guinea (224)</option>
                                <option value="164">Guinea-Bissau (245)</option>
                                <option value="86">Guyana (592)</option>
                                <option value="87">Haiti (509)</option>
                                <option value="89">Honduras (504)</option>
                                <option value="88">Hong Kong (852)</option>
                                <option value="91">Hungary (36)</option>
                                <option value="92">Iceland (354)</option>
                                <option value="12">India (91)</option>
                                <option value="93">Indonesia (62)</option>
                                <option value="95">Iran(98)</option>
                                <option value="99">Iraq (964)</option>
                                <option value="59">Ireland (353)</option>
                                <option value="228">Isle of Man(44)</option>
                                <option value="96">Israel (972)</option>
                                <option value="97">Italy (39)</option>
                                <option value="102">Jamaica (1)</option>
                                <option value="100">Japan (81)</option>
                                <option value="229">Jersey(44)</option>
                                <option value="103">Jordan (962)</option>
                                <option value="111">Kazakhstan (7)</option>
                                <option value="104">Kenya (254)</option>
                                <option value="107">Kiribati (686)</option>
                                <option value="110">Kuwait (965)</option>
                                <option value="105">Kyrgyzstan (996)</option>
                                <option value="112">Lao People's (856)</option>
                                <option value="240">Laos (856)</option>
                                <option value="114">Latvia (371)</option>
                                <option value="113">Lebanon (961)</option>
                                <option value="119">Lesotho (266)</option>
                                <option value="116">Liberia (231)</option>
                                <option value="121">Libya (218)</option>
                                <option value="118">Liechtenstein (423)</option>
                                <option value="115">Lithuania (370)</option>
                                <option value="120">Luxembourg (352)</option>
                                <option value="124">Macao(853)</option>
                                <option value="130">Macedonia(389)</option>
                                <option value="122">Madagascar (261)</option>
                                <option value="129">Malawi (265)</option>
                                <option value="140">Malaysia (60)</option>
                                <option value="138">Maldives (960)</option>
                                <option value="131">Mali (223)</option>
                                <option value="136">Malta (356)</option>
                                <option value="231">
                                  Marshall Islands (692)
                                </option>
                                <option value="123">Martinique (596)</option>
                                <option value="135">Mauritania (222)</option>
                                <option value="134">Mauritius (230)</option>
                                <option value="126">Mayotte (269)</option>
                                <option value="139">Mexico (52)</option>
                                <option value="101">
                                  Micronesia, Federated States of(691)
                                </option>
                                <option value="125">Moldova(373)</option>
                                <option value="132">Monaco (377)</option>
                                <option value="127">Mongolia (976)</option>
                                <option value="230">Montenegro(382)</option>
                                <option value="128">Montserrat (1)</option>
                                <option value="133">Morocco (212)</option>
                                <option value="141">Mozambique (258)</option>
                                <option value="25">Myanmar (95)</option>
                                <option value="217">Namibia (264)</option>
                                <option value="151">Nauru (674)</option>
                                <option value="150">Nepal (977)</option>
                                <option value="148">Netherlands (31)</option>
                                <option value="153">
                                  Netherlands Antilles (599)
                                </option>
                                <option value="142">New Caledonia (687)</option>
                                <option value="155">New Zealand (64)</option>
                                <option value="154">Nicaragua (505)</option>
                                <option value="145">Niger (227)</option>
                                <option value="147">Nigeria (234)</option>
                                <option value="143">Niue (683)</option>
                                <option value="144">Norfolk Island(672)</option>
                                <option value="108">North Korea(82)</option>
                                <option value="232">
                                  Northern Mariana Islands (1)
                                </option>
                                <option value="149">Norway (47)</option>
                                <option value="137">Oman (968)</option>
                                <option value="159">Pakistan (92)</option>
                                <option value="235">Palau (680)</option>
                                <option value="234">Palestine(970)</option>
                                <option value="161">Panama (507)</option>
                                <option value="163">
                                  Papua New Guinea (675)
                                </option>
                                <option value="156">Paraguay (595)</option>
                                <option value="158">Peru (51)</option>
                                <option value="168">Philippines (63)</option>
                                <option value="157">Pitcairn(64)</option>
                                <option value="160">Poland (48)</option>
                                <option value="162">Portugal (351)</option>
                                <option value="233">Puerto Rico (1)</option>
                                <option value="165">Qatar (974)</option>
                                <option value="166">Reunion (262)</option>
                                <option value="167">Romania (40)</option>
                                <option value="169">
                                  Russian Federation(7)
                                </option>
                                <option value="170">Rwanda (250)</option>
                                <option value="177">Saint Helena(290)</option>
                                <option value="173">
                                  Saint Kitts and Nevis(1869)
                                </option>
                                <option value="184">Saint Lucia (1)</option>
                                <option value="172">
                                  Saint Pierre and Miquelon(508)
                                </option>
                                <option value="213">
                                  Saint Vincent and the Grenadines(1-784)
                                </option>
                                <option value="221">Samoa (685)</option>
                                <option value="180">San Marino (378)</option>
                                <option value="198">
                                  Sao Tome and Principe (239)
                                </option>
                                <option value="171">Saudi Arabia (966)</option>
                                <option value="176">Senegal (221)</option>
                                <option value="236">Serbia(381)</option>
                                <option value="174">Seychelles (248)</option>
                                <option value="179">Sierra Leone (232)</option>
                                <option value="181">Singapore (65)</option>
                                <option value="117">Slovakia (421)</option>
                                <option value="178">Slovenia (386)</option>
                                <option value="28">Solomon Islands(677)</option>
                                <option value="182">Somalia (252)</option>
                                <option value="175">South Africa (27)</option>
                                <option value="188">South Georgia(500)</option>
                                <option value="106">South Korea(82)</option>
                                <option value="183">Spain (34)</option>
                                <option value="37">Sri Lanka (94)</option>
                                <option value="185">Sudan (249)</option>
                                <option value="152">Suriname (597)</option>
                                <option value="186">Svalbard(47)</option>
                                <option value="222">Swaziland (268)</option>
                                <option value="187">Sweden (46)</option>
                                <option value="190">Switzerland (41)</option>
                                <option value="189">Syria(963)</option>
                                <option value="203">Taiwan(886)</option>
                                <option value="193">Tajikistan (992)</option>
                                <option value="205">Tanzania(255)</option>
                                <option value="192">Thailand (66)</option>
                                <option value="200">Timor-Leste(670)</option>
                                <option value="197">Togo (228)</option>
                                <option value="195">Tokelau (690)</option>
                                <option value="196">Tonga (676)</option>
                                <option value="191">
                                  Trinidad and Tobago(1 (868))
                                </option>
                                <option value="199">Tunisia (216)</option>
                                <option value="201">Turkey (90)</option>
                                <option value="204">Turkmenistan (993)</option>
                                <option value="194">
                                  Turks and Caicos Islands(1‑649)
                                </option>
                                <option value="202">Tuvalu (688)</option>
                                <option value="206">Uganda (256)</option>
                                <option value="208">Ukraine (380)</option>
                                <option value="3">
                                  United Arab Emirates (971)
                                </option>
                                <option value="207">United Kingdom (44)</option>
                                <option value="209">United States(1)</option>
                                <option value="211">Uruguay (598)</option>
                                <option value="212">Uzbekistan (998)</option>
                                <option value="146">Vanuatu (678)</option>
                                <option value="238">Vatican City (379)</option>
                                <option value="214">Venezuela (58)</option>
                                <option value="216">Vietnam (84)</option>
                                <option value="215">
                                  Virgin Islands, British(1284)
                                </option>
                                <option value="237">
                                  Virgin Islands, U.S.(1340)
                                </option>
                                <option value="219">
                                  Wallis and Futuna(681)
                                </option>
                                <option value="220">
                                  Western Sahara (212)
                                </option>
                                <option value="224">Yemen (967)</option>
                                <option value="241">
                                  Yugoslavia (Former) (381)
                                </option>
                                <option value="225">Zambia (260)</option>
                                <option value="226">Zimbabwe (263)</option>
                              </select>
                                                    </div>

                                                    <div class="col-sm-7 pl-md-0">
                                                        <input type="text" class="form-control" placeholder="mobile.. " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Gender *</label>

                                                <div class="clearfix"></div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" />
                                                    <label class="custom-control-label" for="customRadioInline1">Male</label
                            >
                          </div>
                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline2"
                              >Female</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group mb-1">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlAutosizing"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlAutosizing"
                              >By clicking register free, you accept our T&C and
                              Privacy Policy</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlAutosizing2"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlAutosizing2"
                              >Receive notification and promotional
                              messages</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <button
                          class="btn btn-outline-success font-weight-bold w-100 p-3 mt-3"
                          onClick={() => setRegTab(2)}
                        >
                          Register Free
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                </>
                ) : null}
                {regTab == 2 ? (
                  <>
                  <div class="step-wizard pt-4 pl-4 pr-4">
                                <h4 class="mb-4">Tell us about your basic details</h4>
                                <ul id="progressbar">
                                    <li class="active">Account Setup</li>
                                    <li class="active">Basic Information</li>
                                    <li>Personal Details</li>
                                    <li>Education Details</li>
                                    <li>Self Description</li>
                                </ul>
                            </div>
                            <div class="form-content p-4">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Date of Birth</label>

                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <select class="form-control mb-sm-20" id="exampleFormControlSelect1">
                                <option>Day</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <select class="form-control mb-sm-20" id="exampleFormControlSelect1">
                                <option>Month</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <select class="form-control" id="exampleFormControlSelect1">
                                <option>Year</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Your Religion *</label
                          >
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Nationality *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Email ID *</label>

                                                <input type="text" class="form-control" placeholder="Email" />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Set Password</label>

                                                <input type="password" class="form-control" placeholder="Password" />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <button class="btn btn-dark float-right" onClick={() => setRegTab(3)}>Next</button>
                      </div>
                    </div>
                  </form>
                </div>
                  </>
                  ) : null}
                {regTab == 3 ? (
                  <>
                    <div class="step-wizard pt-4 pl-4 pr-4">
                                <h4 class="mb-4">Let's create your profile now</h4>
                                <ul id="progressbar">
                                    <li class="active">Account Setup</li>
                                    <li class="active">Basic Information</li>
                                    <li class="active">Personal Details</li>
                                    <li>Education Details</li>
                                    <li>Self Description</li>
                                </ul>
                            </div>

                            <div class="form-content p-4">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Height *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Weight *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Select Marital Status *</label
                          >
                          <div class="clearfix"></div>

                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline3"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline3"
                              >Never Married</label
                            >
                          </div>

                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline4"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline4"
                              >Windowed/Windower</label
                            >
                          </div>

                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline5"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline5"
                              >Divorced</label
                            >
                          </div>

                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline6"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline6"
                              >Awaiting Divorce</label
                            >
                          </div>

                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline7"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline7"
                              >Nikah Divorce</label
                            >
                          </div>

                          <div
                            class="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              type="radio"
                              id="customRadioInline7"
                              name="customRadioInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadioInline7"
                              >Married</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1"
                            >Skin Tone (Complexion) *</label
                          >
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Ethnic Group *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Body Type *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Physically Challenged ? *</label
                          >

                          <div class="clearfix">
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                class="custom-control-input"
                              />
                              <label
                                class="custom-control-label"
                                for="customRadioInline2"
                                >Yes</label
                              >
                            </div>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="customRadioInline3"
                                name="customRadioInline1"
                                class="custom-control-input"
                              />
                              <label
                                class="custom-control-label"
                                for="customRadioInline3"
                                >NO</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <button
                          class="btn btn-dark float-right ml-3"
                          onClick={() => setRegTab(4)}
                        >
                          Next
                        </button>
                        <button
                          class="btn btn-dark float-right opacity-2"
                          onClick={() => setRegTab(2)}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                  </>
                ) : null}
                {regTab == 4 ? (
                  <>
                    <div class="step-wizard pt-4 pl-4 pr-4">
                                <h4 class="mb-4">
                                    Tell us about your education and location
                                </h4>
                                <ul id="progressbar">
                                    <li class="active">Account Setup</li>
                                    <li class="active">Basic Information</li>
                                    <li class="active">Personal Details</li>
                                    <li class="active">Education Details</li>
                                    <li>Self Description</li>
                                </ul>
                            </div>

                            <div class="form-content p-4">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Highest Education</label
                          >

                          <div class="row">
                            <div class="col-md-6">
                              <select
                                class="form-control mb-sm-20"
                                id="exampleFormControlSelect1"
                              >
                                <option>Select</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Select</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Proffession *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Profession Type</label
                          >
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Home Location*</label>

                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <select class="form-control mb-sm-20" id="exampleFormControlSelect1">
                                <option>India</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <select class="form-control mb-sm-20" id="exampleFormControlSelect1">
                                <option>State</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <select class="form-control" id="exampleFormControlSelect1">
                                <option>City</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Present Location*</label
                          >

                          <div class="row">
                            <div class="col-md-4">
                              <select
                                class="form-control mb-sm-20"
                                id="exampleFormControlSelect1"
                              >
                                <option>India</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                            <div class="col-md-4">
                              <select
                                class="form-control mb-sm-20"
                                id="exampleFormControlSelect1"
                              >
                                <option>State</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                            <div class="col-md-4">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>City</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <button
                          class="btn btn-dark float-right ml-3"
                          onClick={() => setRegTab(5)}
                        >
                          Next
                        </button>
                        <button
                          class="btn btn-dark float-right opacity-2"
                          onClick={() => setRegTab(3)}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                  </>
                ) : null}
                {regTab == 5 ? (
                  <>
                    <div class="step-wizard pt-4 pl-4 pr-4">
                                <h4 class="mb-4">Tell us about your details</h4>
                                <ul id="progressbar">
                                    <li class="active">Account Setup</li>
                                    <li class="active">Basic Information</li>
                                    <li class="active">Personal Details</li>
                                    <li class="active">Education Details</li>
                                    <li class="active">Self Description</li>
                                </ul>
                            </div>

                            <div class="form-content p-4">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Mother Tongue *</label
                          >
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Community *</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Religiousness*</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Perform Namaz*</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Eating Habits</label>
                                                <select class="form-control" id="exampleFormControlSelect1">
                            <option>Select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Profile Description*
                          </label>

                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-dark float-right ml-3">
                          Finish
                        </button>
                                            <button class="btn btn-dark float-right opacity-2" onClick={() => setRegTab(4)}>
                          Previous
                        </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="home-features-section">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-12 col-sm-auto">
              <div class="feature-box d-flex">
                <div class="mr-2">
                  <img src="images/icon-registration.png" width="33" />
                </div>
                <h6>
                  Free <br />
                  Registration
                </h6>
              </div>
            </div>
            <div class="col-12 col-sm-auto">
              <div class="feature-box d-flex">
                <div class="mr-2">
                  <img
                    src="images/icon-trusted-profile.png"
                    width="37"
                    height="41"
                  />
                </div>
                <h6>
                  Trusted <br />
                  Profiles
                </h6>
              </div>
            </div>

            <div class="col-12 col-sm-auto">
              <div class="feature-box d-flex">
                <div class="mr-2">
                  <img src="images/icon-online-support.png" width="37" />
                </div>
                <h6>
                  Online<br />
                  Support
                </h6>
              </div>
            </div>
            <div class="col-12 col-sm-auto">
              <div class="feature-box d-flex flex-grow-1">
                <div class="mr-2">
                  <img src="images/icon-data-securty.png" width="37" />
                </div>
                <h6>
                  Best Data Security<br />
                  and Privacy
                </h6>
              </div>
            </div>
            <div class="col-12 col-sm-auto">
              <div class="feature-box d-flex">
                <div class="mr-2">
                  <img
                    src="images/icon-advanced-search.png"
                    width="37"
                    height="32"
                  />
                </div>
                <h6>
                  Advanced Search <br />
                  Criteria
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="why-malayalanikah-section">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <img src="images/why-img.png" class="img-fluid" alt="image" />
            </div>

            <div class="col-md-7">
              <h2>WHY <span class="text-secondary">MALAYALA NIKAH?</span></h2>

              <p>
                In Islam we believe nikah to be a blessing bestowed upon us by
                Allah (SWT). It has been mentioned several times in the Quran
                and holds utmost importance, and acts as a barrier between halal
                and haram. It has been said that when a person gets married, he
                completes half of his deen. We, at Malayala Nikah, understand
                the importance of choosing the right one, to be with you in this
                life and the hereafter, and who would complete half of your deen
              </p>

              <p>
                Malayala Nikah is India’s most trusted Malayala Nikah site,
                providing matrimonial services to Muslim brothers and sisters.
                Our matrimony site for Muslims connects millions of people who
                are in search for their Matrimony one, and we help them find the
                one they are destined to be with. Our aim is to help all
                Muslims- singles, divorced, widowed, to find the perfect one to
                spend the rest of their lives with.
              </p>
            </div>
          </div>
        </div>
      </section>
      

      <section class="home-profile-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2 class="flourish-head">Featured Profiles</h2>
            </div>

            <div class="col-md-12">
              <div class="profile mt-3">
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="profile-box">
                    <img
                      src="images/profile1.png"
                      class="d-block mx-auto"
                      width="120px"
                    />
                    <div class="prof-details text-center">
                      <h5>Marzooqa Fathima</h5>
                      <p>28 Years</p>
                      <p>Cochin</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="app-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 align-items-center d-flex">
              <div>
                <h3>
                  Stay up-to-date <br />
                  with your partner<br />
                  search using our <br />
                  Malayala Nikah App
                </h3>
                <a class="mt-3 d-inline-block" href="#"
                  ><img src="images/google-play-btn.png" width="200"
                /></a>
              </div>
            </div>
            <div class="col-md-6">
              <img src="images/app-img.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>
        </>
    )

}

export default Home;