import { useNavigate } from "react-router-dom";

function Login() {

  const navigate = useNavigate();
  const login = (e) => {
    e.preventDefault();

    window.$("#login-popup").modal('toggle');
    navigate("dashboard");
  }

  return (
        <div
      class="modal-login modal fade"
      id="login-popup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="mb-3">
              Welcome Back!<br />
              Please Login
            </h4>
            <form action="" onSubmit={login}>
              <div class="form-group">
                <label for="">User Name</label>
                                                    <input type="text" class="form-control" id="usernamepopup" placeholder="Profile ID / Mobile Number / Email Address" />
                                                </div>

                                                <div class="form-group">
                                                    <label for="">Password</label>
                                                    <input type="Password" class="form-control" id="passwordpopup" placeholder="Password" />
                                                    <p class="text-right forgot mt-1">
                                                        <a onclick="forgotpassword(1)">Forgot Password?</a>
                                                    </p>
                                                    <div class="errorlabel text-center" id="loginerror"></div>
                                                </div>

                                                <button type="submit" class="btn btn-primary btn-block">Login</button>

                                                <hr class="mt-4" />

                                                <a class="btn btn-primary btn-block text-left icon-button" data-toggle="modal" data-target="#mobile-popup">
                                                    <span><i class="fa fa-mobile ml-1"></i></span> Login with Mobile OTP
                                                </a>
                                                <a class="btn btn-primary btn-block text-left icon-button">
                                                    <span><i class="fa fa-envelope"></i></span> Login with Email OTP
                                                </a>
                                </form>

                                <p class="text-center mt-4 mb-3">
                                    New Here?
                                    <a href="#">Register Free</a>
                                </p>
                                </div>
                                </div>
                                </div>
                            </div>
  );
}

export default Login;