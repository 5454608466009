import { Outlet } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./components/Login";

function DefaultLayout() {
    return (
        <>
            <div class="wrapper">
                <Header />

                <Outlet />

                <Footer />

                <a href="#" id="back-to-top" title="Back to top">&uarr;</a>
            </div>
            <Login />
        </>
    )
}

export default DefaultLayout;