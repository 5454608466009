import { Link } from "react-router-dom";

function Header() {
    
    return (
        <header>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="menu-row">
                            <div class="logo-wrap">
                                <div class="logo-wrap-in">
                                    <Link to="/">
                                        <img src="images/logo.png" class="img-fluid mt-3" alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div class="menu-wrap">
                                <div class="menu-right">
                                    <div class="button-wrap">
                                        <a data-toggle="modal" data-target="#login-popup" class="signup-btn">
                        Login
                      </a>
                                    </div>
                                </div>
                                <div class="menu-left">
                                    <div id="nav_list" class="menu-mobile-icon">
                                        <div id="toggle-icon">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div class="main-navigation pushmenu pushmenu-left">
                                        <nav id="nav" class="nav">
                                            <ul class="main-menu-list">
                                                <li class="active"><Link to="/">Home</Link></li>
                                                <li><a href="#">About</a></li>
                                                <li><Link to="contactus">Contact Us</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;