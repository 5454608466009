function CancellationReturnPolicy() {

    return (
        <div class="contact-warp headerafter cms-page">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-head text-center">
                            <h2>Cancelation & Return Policy</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <h4>Scope</h4>

                        <p>
                            You understand and acknowledge that Waytonikah.com acts as an "Intermediary" as defined under clause (1) sub-clause (w) of Section 2 of the Information Technology Act, 2000. Waytonikah.com is a brand owned by NIKA ONLINE PRIVATE LIMITED which owns, retains
                            and has complete rights in Waytonikah.com and the Waytonikah.com Website /App/ Waytonikah.com Service.
                        </p>

                        <h4>Account Security</h4>

                        <p>
                            Waytonikah.com as a Company does not ask for Password and you are cautioned not to share your password to any persons. You are responsible for safeguarding the confidentiality of your Waytonikah.com login credentials such as your user id, password, OTP,
                            etc. You agree to accept responsibility for all activities that occur over your account
                        </p>

                        <p>
                            This site does not encourage and/or promote illegitimate sexual relations or affairs outside marriage. If any member is found to be using this site as a means of promoting or indulging or engaging in any illegitimate sexual relations or affairs outside
                            marriage or if Waytonikah.com becomes aware of such activities then the membership of the particular user will be terminated without any refund. Waytonikah.com is not bound by any liability towards such individual. The binding
                            and final termination will be the sole discretion of Waytonikah.com
                        </p>

                        <ul>
                            <li>liststyle uses 1</li>
                            <li>liststyle uses 2</li>
                        </ul>

                        <ol>
                            <li>liststyle uses 1</li>
                            <li>liststyle uses 2</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CancellationReturnPolicy;