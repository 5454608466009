import { Link } from "react-router-dom";

function Footer() {
    
  return (
        <footer>
        <div class="footer-contact-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="get-start-box">
                  <div class="row">
                    <div class="col-md-6">
                      <h3>Find your Matrimony one</h3>
                    </div>
                    <div class="col-md-6">
                      <a href="#" class="btn btn-dark btn-get-start"
                        >Get Started</a
                      >
                    </div>
                  </div>
                </div>
                <div class="footer-contacts">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="contact-box d-flex">
                        <div class="mr-3">
                          <img src="images/icon-mail.png" />
                        </div>

                        <div class="contact-content">
                          <h5>Send Your Queries</h5>
                          <p>info@malayalanikah.com</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="contact-box d-flex">
                        <div class="mr-3">
                          <img src="images/icon-phone.png" />
                        </div>

                        <div class="contact-content">
                          <h5>Helpline 24X7</h5>
                          <p>+91 9064100695</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="contact-box d-flex">
                        <div class="mr-3">
                          <img src="images/icon-map.png" />
                        </div>

                        <div class="contact-content">
                          <h5>Head Office</h5>
                          <p>Trivandrum Technopark, India</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-5 justify-content-center">
                <img src="images/logo.png" width="200" />
                {/* <h3>Address</h3> */}
                <p class="about-content mt-2">
                  We, at Malayala Nikah, are committed towards helping you find
                  the one for whom you are destined to spend the rest of your
                  lives. Our goal is to shape Malayala Nikah into a journey of
                  compassion and trust for you.
                </p>
              </div>
              <div class="col-12 col-lg-7">
                {/* <h3>Company</h3> */}
                <div class="row">
                  <div class="col-md-4">
                    <h3>Quick Links</h3>

                    <div class="footer-links">
                      <a href="#">Enterprise</a>
                      <a href="#">Customer Story</a>
                      <a href="#">Security</a>
                      <a href="#">Pricing</a>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <h3>Explore</h3>

                    <div class="footer-links">
                      <a href="#">Testimonials</a>
                      <a href="#">Success Stories</a>
                      <Link to="terms-and-conditions">Terms and Conditions</Link>
                      <Link to="privacy-policy">Privacy Policy</Link>
                      <Link to="cancellation-return-policy">Cancelation & Return Policy</Link>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="social-list">
                      <a href="#" target="_blank" class="ml-0"
                        ><i class="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                      <a href="#" target="_blank"
                        ><i class="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                      <a href="#" target="_blank"
                        ><i class="fa fa-youtube-play" aria-hidden="true"></i
                      ></a>
                    </div>

                    <a class="mt-1 d-inline-block" href="#"
                      ><img src="images/google-play-btn.png" width="140"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-12 copy-right text-left">
                <p>© 2021 Malayala Nika. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer;