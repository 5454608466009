function Contactus() {

    return (
        <div class="contact-warp headerafter">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-head text-center">
                            <h2>Contact Us</h2>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-form">
                            <div class="row mt-3">
                                <div class="col-md-6 mt-3">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" value="" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" value="" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" value="" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label>Phone Number</label>
                                    <input type="text" class="form-control" value="" />
                                </div>

                                <div class="col-md-12 mt-3">
                                    <label>Message</label>
                                    <textarea class="form-control"></textarea>
                                </div>

                                <div class="col-md-12 mt-3 text-right">
                                    <button class="btn btn-dark">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mt-5 contact-page-address">
                            <h5>Trivandrum</h5>
                            <p>Trivandrum Technopark, India <br />Mob: 9898 568659</p>
                        </div>
                        <div class="mt-5 contact-page-address">
                            <h5>Calicut</h5>
                            <p>Calicut cyberpark, India <br />Mob: 9898 565656</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contactus;