function Dashboard() {

    return (
        <div class="col-lg-9">
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="card-white p-4 border-0 d-flex justify-content-around">

                                                <div>
                                                    <h3>5</h3>
                                                    <p>Remaining Interests</p>
                                                </div>

                                                <div class="db-icon">

                                                    <img src="images/icon-love.png" height="40" />
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card-white p-4 border-0 d-flex justify-content-around">

                                                <div>
                                                    <h3>10</h3>
                                                    <p>Remaining Contact View</p>
                                                </div>

                                                <div class="db-icon">

                                                    <img src="images/ico-phone.png" height="40" />
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="card-white p-4 border-0 d-flex justify-content-around">

                                                <div>
                                                    <h3>2</h3>
                                                    <p>Gallery Uploads</p>
                                                </div>

                                                <div class="db-icon">

                                                    <img src="images/icon-gallery.png" height="40" />
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-8">

                                            <div class="card-white p-4 border-0">
                                                <div class="card-head d-flex justify-content-between">
                                                    <h4>Current Package</h4>
                                                    <label class="default-label">Default Package</label>

                                                </div>

                                                <div class="card-white-body">
                                                    <img src="images/defalt-db.png" class="img-fluid" />
                                                </div>
                                            </div>


                                        </div>

                                        <div class="col-md-4">
                                            <div class="card-white p-4 border-0">
                                                <div class="card-head d-flex justify-content-between">
                                                    <h4>Matched Profile</h4>


                                                </div>

                                                <div class="card-white-body">
                                                    <p>Update your package for Auto Match Making</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                            </div>
                        </div>
    );
}

export default Dashboard;